import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 10px;
`
export const List = styled.div`
  cursor: pointer;
  align-items: flex-end;
  padding: 1em 0.5em;
  border-bottom: 0.5px solid #d8d8d8;
  display: flex;
  img {
    width: 24px;
  }
  span {
    flex: 1;
    font-size: 14px;
    line-height: 19px;
  }
`

export const CheckedImg = styled.img`
  width: 14px !important;
  margin: auto;
`



import styled from 'styled-components'

const Currency = styled.span`
  color: #3a3a3a;
  position: absolute;
  display: block;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  padding: 5px;
  left: 5px;
  bottom: 8px;
  border: 0.5px solid #e8e8e8;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
`

const TextFieldWrapper = styled.div`
  position: relative;
  height: 50px;
  overflow: hidden;
  border-radius: 3px;
`

const TextFieldLabel = styled.label`
  position: absolute;
  font-size: 12px;
  bottom: 0;
  left: 40px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  color: #989898;
  border-bottom: 1px solid
    ${props => (props.isError ? '#f80505' : '#d8d8d8')};

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
`

const TextFieldSpan = styled.span`
  position: absolute;
  bottom: 5px;
  left: 0;
  transition: all 0.3s ease;
`

const TextFieldInput = styled.input`
  font-weight: 600;
  font-size: 20px;
  width: 85%;
  height: ${props => props.height || '45px'};
  color: #595f6e;
  padding-top: 20px;
  bottom: 20px;
  margin-left: 40px;
  border: none;
  outline: none;
  background: #fff;
  transition: all 0.3s;
  border-radius: 3px;
  flex-grow: 1;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
    background: rgba(0,0,0,0.3);
  }

  &[readOnly] {
    opacity: 0.4;
    background: rgba(0,0,0,0.3);
  }

  &[type='password'] {
    font-size: 1em;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  & + input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus
    + ${TextFieldLabel}
    ${TextFieldSpan},
    &:valid
    + ${TextFieldLabel}
    ${TextFieldSpan} {
    transform: translateY(-100%);
    margin-bottom: 10px;

    left: -30px;
  }

  &:focus + ${TextFieldLabel}::after, &:valid + ${TextFieldLabel}::after {
    transform: translateX(0%);

    ${TextFieldLabel} {
      border-bottom: none;
    }
  }
`

const LabelInputError = styled.span`
  font-size: 12px;
  color: #f80505;
  margin: 5px 0 0 0;
  display: flex;
`

export {
  Currency,
  TextFieldWrapper,
  TextFieldLabel,
  TextFieldSpan,
  TextFieldInput,
  LabelInputError,
}

import React from 'react'
import {
  Container,
  Wrapper,
  SortContainer,
  IconNav,
  CategoryContainer,
  NavContainer,
  Inner,
  Outer,
} from './style'
import sortingIcon from './assets/Sort.png'
import filterIcon from './assets/Filter.png'

export const NavbarBottom = props => {
  return (
    <Container>
      <Wrapper>
        <NavContainer>
          <SortContainer onClick={() => props.onClickSorting()}>
            <IconNav src={sortingIcon} alt="sort" />
            <p>Urutkan</p>
          </SortContainer>
          <Outer>
            <Inner />
          </Outer>
          <CategoryContainer onClick={() => props.onClickCategory()}>
            <IconNav src={filterIcon} alt="category" />
            <p>Kategori</p>
          </CategoryContainer>
        </NavContainer>
      </Wrapper>
    </Container>
  )
}

export default NavbarBottom

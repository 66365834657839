import React from 'react'
import { Main } from './style'

const Layout = props => (
  <React.Fragment>
    <Main
      padding={props.padding}
      id={props.id}
      style={props.style}
    >
      {props.children}
    </Main>
  </React.Fragment>
)

export default Layout

/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react'
import {
  Button,
  SwitchToggle,
  Textarea,
  FlashMessage
} from '@3pp/ui-library';
import { fetchData } from '@3pp/utils'
import theme from 'constants/theme'
import ContributeNominal from './ContributeNominal'
import queryString from 'query-string'
import DonorInfo from './DonorInfo'
import { eventTracker } from '@3pp/utils'
import LoaderImg from './assets/loader.gif'
import customHeaders from 'utils/customHeaders'
import { setOpenCodeCookie } from 'utils/computeAssignmentForVisitorId'
import {
  Divider,
  Container,
  Section,
  SectionHeading,
  DonationButtonContainer
} from './styles'

const ContributePage = props => {
  const [formLoading, setFormLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [donationFailed, setDonationFailed] = useState(false)
  const [anonym, setAnonym] = useState(false)
  const [name, setName] = useState('')
  const [email_or_phone, setEmailOrPhone] = useState('')
  const [useComment, setUseComment] = useState(false)
  const [comment, setComment] = useState('')
  const [amount, setAmount] = useState('')
  const [campaign, setCampaign] = useState(null)
  const [loading, setLoading] = useState(true)
  const [nameError, setNameError] = useState('')
  const [emailPhoneError, setEmailPhoneError] = useState('')
  const [wcoPaymentUrl, setWcoPaymentUrl] = useState('')
  const urlParams = queryString.parse(props.location.search)

  useEffect(() => {
    const fetchCampaign = async () => {
      const campaignData = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/campaign/${props.match.params.shortUrl}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      })
      setCampaign(campaignData.data[0])
      setLoading(false)
    }
    fetchCampaign()
    //eslint-disable-next-line
  }, [])

  const isValid = () => {
    return !!(amount && name && email_or_phone && nameError === '' && emailPhoneError === '')
  }

  const isIOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const submitData = () => {
    const utmSource = urlParams.utm_source || '3pp_linkaja';
    const utmMedium = urlParams.utm_medium || null;
    const utmCampaign = urlParams.utm_campaign || null;
    const utmContent = urlParams.utm_content || null;
    //const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const paymentMethodID = isIOS() ? 26 : 40

    const utm = {
      source: Array.isArray(utmSource) ? utmSource[0] : utmSource,
      medium: Array.isArray(utmMedium) ? utmMedium[0] : utmMedium,
      campaign: Array.isArray(utmCampaign) ? utmCampaign[0] : utmCampaign,
      content: Array.isArray(utmContent) ? utmContent[0] : utmContent
    }

    const generalData = {
      name,
      email_or_phone: email_or_phone.replace(/\s+$/, ''),
      payment_methods_id: paymentMethodID,
      platform: 'linkaja',
      send_notification: true,
      redirect_callback: `${process.env.REACT_APP_KITABISA_URL}/donation/status/`,
    }
    const campaignSpecificData = {
      campaign_id: campaign.id,
      amount: Number(amount),
      comment: comment,
      campaigner_relation: false,
      is_anonymous: anonym,
      utm: {
        ...utm
      }
    }

    let body = {}
    body = {
      ...generalData,
      ...campaignSpecificData
    }

    const doSubmit = async () => {
      setFormLoading(true)
      try {
        const submittedData = await fetchData({
          url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/non-login-donation`,
          method: 'POST',
          body: body,
          additionalHeaders: customHeaders
        })
        if (submittedData.data) {
          setOpenCodeCookie(submittedData.data[0].open_code, submittedData.data[0].id)
          setFormLoading(false)
          eventTracker('lanjutkan pembayaran contribute linkaja')
          if (paymentMethodID === 26) {
            setWcoPaymentUrl(`https://payment.linkaja.id/checkout/payment?message=${submittedData.data[0]['payment_token']}`)
            document.getElementById('linkaja_wco').submit()
          } else {
            window.location = submittedData.data[0]['payment_checkout_url']
          }

        } else {
          setFormLoading(false)
          setDonationFailed(true)
          setErrorMessage(submittedData.errors[0].details.id)
        }
      } catch (error) {
        return window.location = `${process.env.REACT_APP_ERROR_URL}?from=${process.env.REACT_APP_BASE_URL}/campaign/${props.match.params.shortUrl}`
      }
    }

    if (isValid()) {
      doSubmit()
    }
  }

  const onValueChange = (values) => {
    eventTracker('field nominal contribute gogive')
    setAmount(values.split('.').join(''))
  }

  if (loading) return null
  return (
    <Container>
      <FlashMessage
        colorType="orange"
        inProp={donationFailed}
        text={errorMessage}
        icon={
          <img
            src="https://assets.kitabisa.xyz/images/icon__alert--warning.svg"
            alt="icon-flash-message"
          />
        }
      />
      <form id="linkaja_wco" action={wcoPaymentUrl} method="POST">
        <input type="hidden" />
      </form>
      <SectionHeading>Pilih Nominal</SectionHeading>
      <ContributeNominal
        onValueChange={onValueChange}
        label="Jumlah Donasi"
        setAmount={(nominal) => setAmount(nominal)}
        amount={amount}
      />
      <SectionHeading>Informasi Donatur</SectionHeading>
      <DonorInfo
        name={name}
        emailOrPhone={email_or_phone}
        emailPhoneError={emailPhoneError}
        setEmailOrPhone={setEmailOrPhone}
        setEmailPhoneError={setEmailPhoneError}
        setName={setName}
        setNameError={setNameError}
        nameError={nameError}
      />
      <Section>
        <SwitchToggle name="anonym" onChange={() => setAnonym(!anonym)} label="Sembunyikan nama saya (donasi anonim)" />
        <SwitchToggle name="comment" onChange={() => setUseComment(!useComment)} label="Tulis komentar (opsional)" />
        {
          (useComment) ? <Textarea onChange={setComment} /> : null
        }
        <div style={{ margin: '10px -16px' }}>
          <Divider />
        </div>
      </Section>
      <DonationButtonContainer>
        <Button
          disabled={!isValid()}
          backgroundColor={theme.color.primary}
          style={{ borderRadius: 20 }}
          height="40px"
          fontSize="14px"
          margin="0 16px"
          onClick={() => {
            eventTracker('button lanjut pembayaran linkaja')
            submitData()
          }}
        >
          {
            (formLoading) ?
              <span><img src={LoaderImg} alt="Submitting Data" /></span>
              :
              <span>LANJUT PEMBAYARAN</span>
          }
        </Button>
      </DonationButtonContainer>
    </Container>
  );
};

export default ContributePage;

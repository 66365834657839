import React, { useState, useEffect } from 'react'
import ExploreComponent from 'components/ExploreComponent'
import { Layout } from '@3pp/ui-library'
import { fetchData } from '@3pp/utils'

const Explore = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [category, setCategory] = useState(null)
  useEffect(() => {
    const getCampaignCategory = async () => {
      try {
        const campaignCategoryList = await fetchData({
          method: 'get',
          url: `${process.env.REACT_APP_CORE_API}/campaign-categories`,
        })

        if (campaignCategoryList.data.length > 0) {
          setCategory(campaignCategoryList.data)
          setIsLoading(false)
        }
      } catch (error) {
        throw new Error(error)
      }
    }
    getCampaignCategory()
  }, [])
  if (isLoading) return null
  return (
    <Layout style={{ padding: '0 16px', background: '#fff', paddingBottom: 50 }}>
      <ExploreComponent
        category={category}
        {...props}
      />
    </Layout>
  )
}

export default Explore

import React from 'react'
import styles from './ProgressBar.scss'

const ProgressBar = ({
  percentage=100,
  height='5px'
}) => {
  return (
    <div
      className={styles['progress-bar__track']}
      style={{
        height,
        backgroundColor: 'rgba(0,0,0,0.1)'
      }}
    >
      <div
        className={styles['progress-bar__filler']}
        style={
          {
            background: 'linear-gradient(90deg, #5BB6FD 0%, #3198E8 100%)',
            height,
            width: percentage >= 100 ? '100%' : `${percentage}%`
          }
        }
      />
    </div>
  )
}

export default ProgressBar

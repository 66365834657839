import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  min-height: calc(100vh - 60px);
`

export const Section = styled.div`
  margin: 10px 0 72px;
  padding: 0 16px;
`

export const DonationButtonContainer = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: center;
  bottom: 0;
  height: 72px;
  max-width: 480px;
  width: 100%;
  background-color: rgba(247, 247, 247, 0.8);
  box-shadow: rgba(157, 157, 157, 0.5) 0px -2px 3px -2px;
`
export const SectionHeading = styled.span`
  background: #F2F3F4;
  color: #989898;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  display: block;
  padding: 7px 20px;
`
export const Divider = styled.hr`
  border-top: 10px solid #f8f8f8;
  border-bottom: none;
  border-left: none;
`

import styled from 'styled-components'

export const Main = styled.main`
  background-color: white;
  position: relative;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  padding: ${props => props.padding || '50px 16px 16px'};
  max-width: 480px;
  box-sizing: border-box;
  min-height: 100vh;
`


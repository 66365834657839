/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react'
import Layout from 'components/common/Layout'
import HomeComponent from 'components/HomeComponent'
import customHeaders from 'utils/customHeaders'
import { fetchData } from '@3pp/utils'

const Home = props => {
  const [banners, setBanners] = useState(null)
  const [loadingBanner, setLoadingBanner] = useState(true)
  const [carouselCampaign, setCarouselCampaign] = useState(null)
  const [listCampaign, setListCampaign] = useState(null)
  useEffect(() => {
    const fetchBanner = () => {
      fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/banners`,
        method: 'GET',
        additionalHeaders: customHeaders,
      }).then(data => {
        if (data.data) {
          setBanners(data.data)
          setLoadingBanner(false)
        } else {
          setLoadingBanner(false)
        }
      })
    }
    const fetchCampaign = () => {
      fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?partner_id=${process.env.REACT_APP_CAROUSEL_PARTNER}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      }).then(data => {
        setCarouselCampaign(data.data[0].data)
      })
    }
    const fetchListCampaign = () => {
      fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?partner_id=${process.env.REACT_APP_CAMPAIGN_PARTNER}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      }).then(data => {

        setListCampaign(data.data[0].data)
      })
    }
    fetchListCampaign()
    fetchBanner()
    fetchCampaign()
  }, [])
  return (
    <Layout>
      <HomeComponent
        loadingBanner={loadingBanner}
        router={props.router}
        banners={banners}
        carouselCampaign={carouselCampaign}
        listCampaign={listCampaign}
        {...props}
      />
    </Layout>
  )
}

export default Home

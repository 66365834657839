import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
`
const InputContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 0 16px;
  background:#fff;
  box-shadow: 0 2px 3px rgba(0,0,0,0.1);
`
const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  padding: 14px 16px;
  a {
    color: $base-color;
    text-decoration: none;
    font-weight: 600;
  }
`
const Input = styled.input`
  border: none;
  background: transparent;
  font-size: 16px;
  line-height: 22px;
  padding: 16px;
  color: rgb(74,74,74);
  width: 100%;
  &:focus {
    outline: none;
  }
`
const SearchResultWrapper = styled.div`
  background-color: white;
  max-width: 480px;
  height: 100vh;
  width: 100%;
  margin: 55px auto 0;
`

const SearchResultNotFound = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: rgb(74, 74, 74);
  text-align: center;
  padding-top: 16px;
  margin: 0 auto;
`

const CloseBtn = styled.button`
  font-size: 16px;
  -webkit-appearance: none;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
`
export {
  Wrapper,
  InputContainer,
  TitleHeader,
  Input,
  SearchResultNotFound,
  SearchResultWrapper,
  CloseBtn
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Suspense} from 'react'
import Header from 'components/common/Header'
import { Carousel } from '@3pp/ui-library'
import NavbarBottom from 'components/NavbarBottom'
import Modal from 'react-modal'
import Sort from 'components/Sort'
import { eventTracker } from '@3pp/utils'
import { Content } from './styles'

const CarouselCampaign = React.lazy(() => import('./CarouselCampaign'))
const ListCampaign = React.lazy(() => import('./ListCampaign'))

Modal.setAppElement('#root')
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router?: any;
  loadingBanner: boolean;
  banners: [
    {
      id: string;
      image: string;
      url: string;
    }
  ];
  carouselCampaign: [
    {
      title: string;
      short_url: string;
      id: number;
      image: string;
      donation_percentage: number;
      donation_received: number;
    }
  ];
  listCampaign: [
    {
      title: string;
      short_url: string;
      id: number;
      image: string;
      donation_percentage: number;
      donation_received: number;
    }
  ];
  history: any;
}
const HomeComponent: React.SFC<Props> = (props) => {
  const [sortingOpen, setSortingOpen] = useState(false)
  const customStyles = {
    content : {
      top                   : '0',
      left                  : '0',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '0',
      width                 : '100%',
      zIndex: '1111'
    }
  }
  const onClickBanner = (index) => {
    eventTracker(`slider banner ${index + 1} linkaja`)
  }
  return (
    <div>
      <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={sortingOpen}
        onAfterOpen={() => {}}
        onRequestClose={() => setSortingOpen(false)}
        style={customStyles}
      >
        <div style={{ width: '100%', height: '100%', background: '#f7f7f7' }}>
          <Sort
            {...props}
            onClose={() => setSortingOpen(false)}
          />
        </div>
      </Modal>
      <Header {...props} />
      <Carousel
        loading={props.loadingBanner}
        onClick={onClickBanner}
        data={props.banners}
        interval={5000}
      />
      <Content>
        <Suspense fallback={<div>Loading...</div>}>
          <CarouselCampaign carouselCampaign={props.carouselCampaign} {...props} />
        </Suspense>
        <section>
          <Suspense fallback={<div>Loading...</div>}>
            <ListCampaign listCampaign={props.listCampaign} {...props} />
          </Suspense>
        </section>
      </Content>
      <NavbarBottom
        {...props}
        onClickSorting={() => {
          eventTracker('button sorting linkaja')
          setSortingOpen(true)
        }}
        onClickCategory={() => {
          props.history.push('/explore')
          eventTracker('button category linkaja')
        }}
      />
    </div>
  )
}

export default HomeComponent
